var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{attrs:{"label":_vm.label,"state":_vm.state},scopedSlots:_vm._u([(_vm.label)?{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")]):_vm._e()]},proxy:true}:null],null,true)},[_c('v-select',{attrs:{"id":"tingee","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"displayName","options":_vm.tingees,"placeholder":"Hình thức thanh toán","disabled":!_vm.enable},on:{"input":_vm.tingeeChanged,"open":_vm.onOpen},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var displayName = ref.displayName;
var accountNumber = ref.accountNumber;
var vaAccountNumber = ref.vaAccountNumber;
var isCash = ref.isCash;
return [_vm._v(" "+_vm._s(displayName)+" "),_c('br'),(!isCash)?_c('cite',[_vm._v(_vm._s(accountNumber + " - " + vaAccountNumber))]):_vm._e()]}},{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Không có dữ liệu ")]}}]),model:{value:(_vm.tingee),callback:function ($$v) {_vm.tingee=$$v},expression:"tingee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }