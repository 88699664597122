import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSchools(ctx, params) {
      return useJwt.getSchools(params).then(response => response);
    },
    exportSchools(ctx, params) {
      return useJwt.exportSchools(params).then(response => response);
    },
    generateImportSchoolTemplate(ctx, params) {
      return useJwt.generateImportSchoolTemplate(params).then(response => response);
    },
    createSchool(ctx, data) {
      return useJwt.createSchool(data).then(response => response);
    },
    updateSchool(ctx, data) {
      return useJwt.updateSchool(data).then(response => response);
    },
    importSchools(ctx, data) {
      return useJwt.importSchools(data).then(response => response);
    },
    getSchoolDetail(ctx, id) {
      return useJwt.getSchoolDetail(id).then(response => response);
    },
    deleteSchools(ctx, data) {
      return useJwt.deleteSchools(data).then(response => response);
    },
  },
};
